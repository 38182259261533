import React from "react";
import { FreeTimeslots } from "../../Admin/AdminAvailability/AdminAvailability";
import Booking from "../Booking";
import * as S from "./AdminBooking.styles";
import { GuestDataProvider } from "../../../hooks/useGuestDataContext";
import { TransformedAppointment } from "../../Admin/AdminAppointments/AdminAppointments";

type Props = {
	timeslots: FreeTimeslots;
	handleNewAppointmentClick: () => Promise<void>;
	onBookingSuccess: () => void;
	previousAppointment?: TransformedAppointment;
};

const AdminBooking = ({
	timeslots,
	handleNewAppointmentClick,
	onBookingSuccess,
	previousAppointment,
}: Props) => {
	return (
		<S.Container>
			<GuestDataProvider>
				<Booking
					isAdmin
					timeslots={timeslots}
					onNewAppointmentClick={handleNewAppointmentClick}
					onBookingSuccess={onBookingSuccess}
					previousAppointment={previousAppointment}
				/>
			</GuestDataProvider>
		</S.Container>
	);
};

export default AdminBooking;
