import styled from "styled-components";
import { colors } from "../../../util/theme";

export const Container = styled.div``;

export const Appointment = styled.div<{ $isBeingModified: boolean }>`
	margin-bottom: 0.5rem;
	border: 2px solid ${colors.gray.dark};
	border-radius: 1rem;
	padding: 0.5rem;

	> :last-child {
		margin-top: 1rem;
		display: flex;
	}

	svg {
		margin-right: 0.5rem;
	}

	${({ $isBeingModified }) =>
		$isBeingModified &&
		`
		button {
			pointer-events: none;
		}
		background-color: yellow;
	`}
`;

export const BookingContainer = styled.div`
	margin-bottom: 1.5rem;

	> :first-child > div {
		justify-content: flex-start;
	}
`;

export const CalendarContainer = styled.div`
	/* TODO: @media  put next to on desktop, under on mobile */
	margin-bottom: 1.5rem;
`;

export const FilterButtons = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 1rem;
`;

export const AppointmentNewDateTitle = styled.div`
	font-size: 1.25rem;
	font-weight: bold;
	margin: 0.25rem;
`;

export const FlagContainer = styled.div`
	display: inline-flex;
	align-items: center;

	svg {
		width: 1.5rem;
		height: 0.75rem;
	}
`;

export const DepositContainer = styled.div<{ depositPaid?: boolean }>`
	display: inline-block;

	path {
		color: ${({ depositPaid }) =>
			depositPaid ? colors.buttons.success.background : colors.buttons.danger};
	}
`;

export const RefreshButtonContainer = styled.div`
	display: flex;
	margin-left: auto;
`;

export const AppointmentButtons = styled.div`
	display: flex;
	gap: 0.5rem;
`;
